<template>
  <div class="flex h-screen flex-col">
    <AppHeader />
    <div>
      <div
        class="overflow-y-auto"
        style="min-height: calc(100vh - 8.25rem);"
      >
        <div class="flex flex-1 flex-col">
          <main
            class="relative flex-1 px-4 focus:outline-none md:px-0"
            tabindex="0"
          >
            <div class="pb-6">
              <slot />
            </div>
          </main>
        </div>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
